import * as React from "react";
import { ReactComponent as Logo } from "./cbssm.svg";
import styles from "./Footer.module.css";

function Footer() {
  const date = new Date().getFullYear();
  return (
    <footer className={styles.footer}>
      <div className={styles.copy}>
        &copy; {date.toString()} Regents of the University of Michigan
      </div>
      <Logo className={styles.logo} />
    </footer>
  );
}

export default Footer;
