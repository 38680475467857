//Styles
import styles from "./ArrayAssembly.module.css";
//Components
import ArrayCanvas from "./ArrayCanvas/ArrayCanvas";
import ArrayOutcomes from "./ArrayOutcomes/ArrayOutcomes";
import ArraySettings from "./ArraySettings/ArraySettings";

export default function ArrayAssembly(props) {
  const { index } = props;
  return (
    <div className={styles.groups}>
      <div className={styles.group1}>
        <ArrayCanvas index={index} download={false} />
      </div>
      <div className={styles.group2}>
        <ArrayOutcomes index={index} />
        <ArraySettings index={index} />
      </div>
    </div>
  );
}
