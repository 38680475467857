import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectProjectTitle,
  setProjectTitle,
  selectProjectTitleColor,
  setProjectTitleColor,
  selectProjectTitleSize,
  setProjectTitleSize,
  selectProjectTitleDisplay,
  setProjectTitleDisplay,
  setProjectSpacing,
  selectProjectSpacing,
} from "../../../redux/reducers/globalSettingsSlice";
//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Slider from "@mui/material/Slider";
//Styles
import styles from "./PreviewSettings.module.css";
//Components

const fontSizes = ["24px", "32px", "48px"];

export default function PreviewSettings() {
  const dispatch = useDispatch();
  const projectTitle = useSelector(selectProjectTitle);
  const projectTitleDisplay = useSelector(selectProjectTitleDisplay);
  const titleColorSetting = useSelector(selectProjectTitleColor);
  const titleSizeSetting = useSelector(selectProjectTitleSize);
  const projectSpacing = useSelector(selectProjectSpacing);
  const [color, setColor] = useState("");
  const [picker, setPicker] = useState(false);

  useEffect(() => {
    setColor(titleColorSetting);
  }, [titleColorSetting]);

  const handlePickerClick = () => {
    setPicker(!picker);
  };

  const handlePickerClose = () => {
    setPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    dispatch(setProjectTitleColor(color.hex));
  };

  const handleSizeChange = (event) => {
    dispatch(setProjectTitleSize(event.target.value));
  };

  const handleTitleChange = (event) => {
    dispatch(setProjectTitle(event.target.value));
  };

  const handleShowHide = () => {
    dispatch(setProjectTitleDisplay({}));
  };

  const handleSpacingChange = (event) => {
    dispatch(setProjectSpacing(event.target.value));
  };

  return (
    <Stack className={styles.controls} spacing={2}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="start"
        className={styles.inner}
      >
        <Box sx={{ mr: 5 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mb: 2, textAlign: "left" }}
          >
            Project Title
          </Typography>
          <Box>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <div style={{ position: "relative", flexShrink: "0" }}>
                <div className={styles.swatch} onClick={handlePickerClick}>
                  <div className={styles.color} style={{ background: color }} />
                </div>
                {picker ? (
                  <div className={styles.popover}>
                    <div className={styles.cover} onClick={handlePickerClose} />
                    <SketchPicker
                      color={color}
                      onChangeComplete={handleColorChange}
                    />
                  </div>
                ) : null}
              </div>
              <FormControl style={{ minWidth: "100px" }}>
                <InputLabel id="titleSize">Size</InputLabel>
                <Select
                  size="small"
                  labelId="titleSize"
                  value={titleSizeSetting}
                  label="titleSize"
                  onChange={handleSizeChange}
                  style={{ minWidth: "100px" }}
                >
                  {fontSizes.map((fontSize) => (
                    <MenuItem key={fontSize} value={fontSize}>
                      {fontSize}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                id="title-text"
                label="Text"
                size="small"
                multiline
                fullWidth
                maxRows={4}
                value={projectTitle}
                onChange={handleTitleChange}
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={projectTitleDisplay}
                    onChange={handleShowHide}
                  />
                }
                label="Show"
              />
            </Stack>
          </Box>
        </Box>
        <Box sx={{ mr: 3 }}>
          <Typography
            variant="h6"
            gutterBottom
            sx={{ mb: 2, textAlign: "left" }}
          >
            Page Layout
          </Typography>
          <Box>
            <Stack
              spacing={2}
              direction="row"
              sx={{ mr: "40px" }}
              alignItems="center"
            >
              <Typography
                variant="button"
                display="block"
                className={styles.label}
              >
                Space Between Arrays
              </Typography>
              <Slider
                aria-label="Spacing"
                value={projectSpacing}
                onChange={handleSpacingChange}
                min={0}
                max={200}
                valueLabelDisplay="on"
                sx={{ width: "150px", color: "#4bb9f4" }}
              />
            </Stack>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}
