import { ReactComponent as Logo } from "./logo.svg";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import styles from "./Header.module.css";

function Header() {
  return (
    <header className={styles.header}>
      <Link to="/" className={styles.logoLink}>
        <Logo className={styles.logo} />
      </Link>
      <nav>
        <Link to="/about">
          <Button variant="text" color="secondary">
            About
          </Button>
        </Link>
      </nav>
    </header>
  );
}

export default Header;
