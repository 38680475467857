import * as React from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectArraySize,
  selectPaddingX,
  setPaddingX,
  selectPaddingY,
  setPaddingY,
} from "../../../../redux/reducers/globalSettingsSlice";
//MUI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
//Styles
import styles from "./IconSettings.module.css";

export default function IconSettings() {
  const dispatch = useDispatch();
  const arraySize = useSelector(selectArraySize);
  const paddingXArray = useSelector(selectPaddingX);
  const paddingYArray = useSelector(selectPaddingY);
  const paddingX = arraySize === 100 ? paddingXArray[0] : paddingXArray[1];
  const paddingY = arraySize === 100 ? paddingYArray[0] : paddingYArray[1];

  const handlePaddingXChange = (event, newValue) => {
    dispatch(setPaddingX(event.target.value));
  };

  const handlePaddingYChange = (event, newValue) => {
    dispatch(setPaddingY(event.target.value));
  };

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      spacing={2}
      alignItems="start"
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="button" display="block" className={styles.label}>
          Icon Padding X
        </Typography>
        <Slider
          aria-label="Volume"
          value={paddingX}
          onChange={handlePaddingXChange}
          min={0}
          max={15}
          valueLabelDisplay="on"
          sx={{ width: "120px", color: "#4bb9f4" }}
        />
      </Stack>
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="button" display="block" className={styles.label}>
          Icon Padding Y
        </Typography>
        <Slider
          aria-label="Volume"
          value={paddingY}
          onChange={handlePaddingYChange}
          min={0}
          max={15}
          valueLabelDisplay="on"
          sx={{ width: "120px", color: "#4bb9f4" }}
        />
      </Stack>
    </Stack>
  );
}
