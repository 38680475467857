import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectBgcolor,
  selectTypeface,
  setProjectSettingsDisplay,
  selectProjectSettingsDisplay,
  selectProjectTitle,
  selectProjectTitleColor,
  selectProjectTitleSize,
  selectProjectTitleDisplay,
  selectProjectSpacing,
  setPreviewDim,
  selectPreviewDim,
} from "../../redux/reducers/globalSettingsSlice";
import { selectArrays } from "../../redux/reducers/arraySettingsSlice";
//MUI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import SettingsIcon from "@mui/icons-material/Settings";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DownloadIcon from "@mui/icons-material/Download";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
//Styles
import styles from "./Preview.module.css";
//Components
import PreviewSettings from "./PreviewSettings/PreviewSettings";
import ArrayCanvas from "../Builder/ArrayAssembly/ArrayCanvas/ArrayCanvas";
//Utilities
import exportImage from "../../utils/exportImage";
//Form Values
const fileTypes = ["jpg", "png", "svg"];

export default function Preview() {
  const dispatch = useDispatch();
  const bgColor = useSelector(selectBgcolor);
  const typeFace = useSelector(selectTypeface);
  const projectTitleDisplay = useSelector(selectProjectTitleDisplay);
  const projectTitle = useSelector(selectProjectTitle);
  const projectTitleColor = useSelector(selectProjectTitleColor);
  const projectTitleSize = useSelector(selectProjectTitleSize);
  const projectSettings = useSelector(selectProjectSettingsDisplay);
  const projectSpacing = useSelector(selectProjectSpacing);
  const previewDim = useSelector(selectPreviewDim);
  const arrays = useSelector(selectArrays);
  const [file, setFile] = useState("jpg");

  useEffect(() => {
    const divWidth = document.getElementById(`download-project`).offsetWidth;
    const divHeight = document.getElementById(`download-project`).offsetHeight;
    const dim = `${divWidth}x${divHeight}`;
    dispatch(setPreviewDim({ dim }));
  }, [dispatch, projectSpacing, projectTitleSize, projectTitleDisplay]);

  const handleSettings = (event) => {
    dispatch(setProjectSettingsDisplay({}));
  };

  const handleFileChange = (event) => {
    setFile(event.target.value);
  };

  const handleDownload = () => {
    const captureElement = document.querySelector("#download-project");
    exportImage(captureElement, file, `icon-array`);
  };

  return (
    <main className={styles.main}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        className={styles.controls}
      >
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <Link to="/">
            <Button variant="contained">
              <ChevronLeftIcon style={{ marginLeft: "-10px" }} /> Back to
              Editing
            </Button>
          </Link>
          <Button variant="contained" onClick={handleSettings}>
            Download Settings{" "}
            {!projectSettings ? (
              <SettingsIcon sx={{ ml: 1 }} />
            ) : (
              <HighlightOffIcon sx={{ ml: 1 }} />
            )}
          </Button>
        </Stack>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <FormControl style={{ minWidth: "100px" }}>
            <InputLabel id="titleSize">File Type</InputLabel>
            <Select
              size="small"
              labelId="titleSize"
              value={file}
              label="titleSize"
              onChange={handleFileChange}
              style={{ minWidth: "100px" }}
            >
              {fileTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" onClick={handleDownload}>
            Download <DownloadIcon sx={{ ml: 1 }} />
          </Button>
        </Stack>
      </Stack>
      {projectSettings && <PreviewSettings />}
      <div className={styles.buffer}></div>
      <div className={styles.project}>
        <div id="download-project" style={{ backgroundColor: bgColor }}>
          {projectTitleDisplay && (
            <div
              className={styles.title}
              style={{
                color: projectTitleColor,
                fontSize: projectTitleSize,
                fontFamily: typeFace,
              }}
            >
              {projectTitle}
            </div>
          )}
          <div className={styles.canvas}>
            {arrays.map((array, index) => {
              return (
                <ArrayCanvas
                  key={`preview-array-${index}`}
                  index={index}
                  download={true}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className={styles.dim}>Download Size: {previewDim}</div>
    </main>
  );
}
