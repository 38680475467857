import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
//Redux
import { useSelector } from "react-redux";
import {
  selectBgcolor,
  selectTypeface,
  selectProjectSpacing,
} from "../../../../redux/reducers/globalSettingsSlice";
import { selectArrays } from "../../../../redux/reducers/arraySettingsSlice";
//Styles
import styles from "./ArrayCanvas.module.css";
//Components
import Array from "./Array/Array";
import Legend from "./Legend/Legend";

export default function ArrayCanvas(props) {
  const { index, download } = props;
  const downloadClass = download ? `${styles.download}` : null;
  const arrays = useSelector(selectArrays);
  const typeFace = useSelector(selectTypeface);
  const spacing = useSelector(selectProjectSpacing);
  const margin = download ? `${spacing}px` : "0px";
  const title = arrays[index].title;
  const titleColor = arrays[index].titleColor;
  const titleSize = arrays[index].titleSize;
  const titleDisplay = arrays[index].titleDisplay;
  const legendDisplay = arrays[index].legendDisplay;
  const labelDisplay = arrays[index].labelDisplay;
  const labelClass = labelDisplay ? `${styles.label}` : null;
  const dim = arrays[index].dim;
  let location = useLocation();
  let isDownload = location.pathname === "/download";
  const [useFiller, setUseFiller] = useState(false);

  useEffect(() => {
    let totalTitles = 0;
    arrays.forEach((array) => {
      if (array.titleDisplay) {
        totalTitles++;
      }
    });
    if (!titleDisplay && totalTitles) {
      setUseFiller(true);
    }
  }, [arrays, titleDisplay]);

  return (
    <>
      <div
        id="array-canavas"
        className={`${styles.canvas} ${downloadClass} ${labelClass}`}
        style={{ marginRight: margin }}
      >
        <div
          id={`array-size-${index}`}
          className={styles.size}
          style={{
            background: useSelector(selectBgcolor),
          }}
        >
          {titleDisplay && (
            <div
              className={`${styles.title} ${styles[typeFace]}`}
              style={{ color: titleColor, fontSize: titleSize }}
            >
              {title}
            </div>
          )}
          {useFiller && (
            <div className={styles.filler} style={{ fontSize: titleSize }}>
              Placeholder
            </div>
          )}
          <div className={styles.canvasInner}>
            <Array index={index} />
          </div>
          {legendDisplay && <Legend index={index} />}
        </div>
      </div>
      {!isDownload && (
        <div className={styles.dim}>
          <span className={styles.value}>Image Size: {dim}</span>
        </div>
      )}
    </>
  );
}
