import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectArrays,
  updateLegendColor,
  updateLegendSize,
  updateLegendDisplay,
} from "../../../../../redux/reducers/arraySettingsSlice";
//MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
//Styles
import styles from "./LegendSettings.module.css";

const fontSizes = ["14px", "16px", "18px"];

export default function LegendSettings(props) {
  const dispatch = useDispatch();
  const { index: arrayIndex } = props;
  const [color, setColor] = useState("");
  const [picker, setPicker] = useState(false);
  const arrays = useSelector(selectArrays);
  const legendColorSetting = arrays[arrayIndex].legendColor;
  const legendSizeSetting = arrays[arrayIndex].legendSize;
  const legendDisplay = arrays[arrayIndex].legendDisplay;

  useEffect(() => {
    setColor(legendColorSetting);
  }, [legendColorSetting]);

  const handlePickerClick = () => {
    setPicker(!picker);
  };

  const handlePickerClose = () => {
    setPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    dispatch(updateLegendColor({ arrayIndex, color: color.hex }));
  };

  const handleSizeChange = (event) => {
    dispatch(updateLegendSize({ arrayIndex, size: event.target.value }));
  };

  const handleShowHide = (event) => {
    //event.target.checked
    dispatch(updateLegendDisplay({ arrayIndex }));
  };

  return (
    <Box className={styles.border} sx={{ mb: 3 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mb: 2, mt: 1, textAlign: "left" }}
      >
        Legend
      </Typography>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <div style={{ position: "relative", flexShrink: "0" }}>
          <div className={styles.swatch} onClick={handlePickerClick}>
            <div className={styles.color} style={{ background: color }} />
          </div>
          {picker ? (
            <div className={styles.popover}>
              <div className={styles.cover} onClick={handlePickerClose} />
              <SketchPicker
                color={color}
                onChangeComplete={handleColorChange}
              />
            </div>
          ) : null}
        </div>
        <FormControl>
          <InputLabel id="titleSize">Size</InputLabel>
          <Select
            size="small"
            labelId="titleSize"
            value={legendSizeSetting}
            label="titleSize"
            onChange={handleSizeChange}
            sx={{ minWidth: 100 }}
          >
            {fontSizes.map((fontSize) => (
              <MenuItem key={fontSize} value={fontSize}>
                {fontSize}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div>
          <FormControlLabel
            control={
              <Switch checked={legendDisplay} onChange={handleShowHide} />
            }
            label="Show"
          />
        </div>
      </Stack>
    </Box>
  );
}
