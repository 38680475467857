//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectTypeface,
  setTypeface,
} from "../../../../redux/reducers/globalSettingsSlice";
//MUI
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
//Form Values
const typefaces = ["sans-serif", "serif"];

export default function FontSettings() {
  const dispatch = useDispatch();

  const handleTypefaceChange = (event) => {
    dispatch(setTypeface(event.target.value));
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <FormControl>
          <InputLabel id="typeface">Typeface</InputLabel>
          <Select
            size="small"
            labelId="typeface"
            value={useSelector(selectTypeface)}
            label="typeface"
            onChange={handleTypefaceChange}
          >
            {typefaces.map((typeface) => (
              <MenuItem key={typeface} value={typeface}>
                {typeface}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    </Box>
  );
}
