import { createSlice } from "@reduxjs/toolkit";

export const globalSettingsSlice = createSlice({
  name: "globalSettings",
  initialState: {
    show: false,
    layout: "10x10",
    arraySize: 100,
    vertical: "bottom to top",
    horizontal: "left to right",
    typeface: "sans-serif",
    random: false,
    title: "32px",
    label: "16px",
    legend: "16px",
    iconWidth: [13, 5],
    iconHeight: [26, 5],
    paddingX: [2, 3],
    paddingY: [2, 3],
    bgcolor: "#FFFFFF",
    defaultIconColor: "#9E9E9E",
    projectSettingsShow: false,
    projectTitle: "This is the project title",
    projectTitleColor: "#000000",
    projectTitleSize: "32px",
    projectTitleDisplay: false,
    projectSpacing: 0,
    dim: "0x0",
  },
  reducers: {
    toggleDisplay: (state, { payload }) => {
      state.show = !state.show;
    },
    setLayout: (state, { payload }) => {
      state.layout = payload;
    },
    setArraySize: (state, { payload }) => {
      state.arraySize = payload;
    },
    setVertical: (state, { payload }) => {
      state.vertical = payload;
    },
    setHorizontal: (state, { payload }) => {
      state.horizontal = payload;
    },
    setTypeface: (state, { payload }) => {
      state.typeface = payload;
    },
    setRandom: (state, { payload }) => {
      state.random = !state.random;
    },
    setTitle: (state, { payload }) => {
      state.title = payload;
    },
    setLabel: (state, { payload }) => {
      state.label = payload;
    },
    setLegend: (state, { payload }) => {
      state.legend = payload;
    },
    setIconSize: (state, { payload }) => {
      state.iconSize = payload;
    },
    setIconHeight: (state, { payload }) => {
      if (state.arraySize === 100) {
        state.iconHeight[0] = payload;
        return;
      }
      state.iconHeight[1] = payload;
    },
    setIconWidth: (state, { payload }) => {
      if (state.arraySize === 100) {
        state.iconWidth[0] = payload;
        return;
      }
      state.iconWidth[1] = payload;
    },
    setPaddingX: (state, { payload }) => {
      if (state.arraySize === 100) {
        state.paddingX[0] = payload;
        return;
      }
      state.paddingX[1] = payload;
    },
    setPaddingY: (state, { payload }) => {
      if (state.arraySize === 100) {
        state.paddingY[0] = payload;
        return;
      }
      state.paddingY[1] = payload;
    },
    setBgcolor: (state, { payload }) => {
      state.bgcolor = payload;
    },
    setDefaultIconColor: (state, { payload }) => {
      state.defaultIconColor = payload;
    },
    setProjectSettingsDisplay: (state, { payload }) => {
      state.projectSettingsShow = !state.projectSettingsShow;
    },
    setProjectTitle: (state, { payload }) => {
      state.projectTitle = payload;
    },
    setProjectTitleColor: (state, { payload }) => {
      state.projectTitleColor = payload;
    },
    setProjectTitleSize: (state, { payload }) => {
      state.projectTitleSize = payload;
    },
    setProjectTitleDisplay: (state, { payload }) => {
      state.projectTitleDisplay = !state.projectTitleDisplay;
    },
    setProjectSpacing: (state, { payload }) => {
      state.projectSpacing = payload;
    },
    setPreviewDim: (state, { payload }) => {
      const { dim } = payload;
      state.dim = dim;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleDisplay,
  setLayout,
  setArraySize,
  setVertical,
  setHorizontal,
  setTypeface,
  setRandom,
  setTitle,
  setLabel,
  setLegend,
  setIconSize,
  setIconHeight,
  setIconWidth,
  setPaddingX,
  setPaddingY,
  setBgcolor,
  setDefaultIconColor,
  setProjectSettingsDisplay,
  setProjectTitle,
  setProjectTitleColor,
  setProjectTitleSize,
  setProjectTitleDisplay,
  setProjectSpacing,
  setPreviewDim,
} = globalSettingsSlice.actions;
export const selectDisplay = (state) => state.globalSettings.show;
export const selectLayout = (state) => state.globalSettings.layout;
export const selectArraySize = (state) => state.globalSettings.arraySize;
export const selectVertical = (state) => state.globalSettings.vertical;
export const selectHorizontal = (state) => state.globalSettings.horizontal;
export const selectTypeface = (state) => state.globalSettings.typeface;
export const selectRandom = (state) => state.globalSettings.random;
export const selectTitle = (state) => state.globalSettings.title;
export const selectLabel = (state) => state.globalSettings.label;
export const selectLegend = (state) => state.globalSettings.legend;
export const selectIconWidth = (state) => state.globalSettings.iconWidth;
export const selectIconHeight = (state) => state.globalSettings.iconHeight;
export const selectPaddingX = (state) => state.globalSettings.paddingX;
export const selectPaddingY = (state) => state.globalSettings.paddingY;
export const selectBgcolor = (state) => state.globalSettings.bgcolor;
export const selectProjectSettingsDisplay = (state) =>
  state.globalSettings.projectSettingsShow;
export const selectDefaultIconColor = (state) =>
  state.globalSettings.defaultIconColor;
export const selectProjectTitle = (state) => state.globalSettings.projectTitle;
export const selectProjectTitleColor = (state) =>
  state.globalSettings.projectTitleColor;
export const selectProjectTitleSize = (state) =>
  state.globalSettings.projectTitleSize;
export const selectProjectTitleDisplay = (state) =>
  state.globalSettings.projectTitleDisplay;
export const selectProjectSpacing = (state) =>
  state.globalSettings.projectSpacing;
export const selectPreviewDim = (state) => state.globalSettings.dim;

export default globalSettingsSlice.reducer;
