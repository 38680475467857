//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectLayout,
  setLayout,
  setArraySize,
  selectVertical,
  setVertical,
  selectHorizontal,
  setHorizontal,
} from "../../../../redux/reducers/globalSettingsSlice";
//MUI
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
//Form Values
const layouts = ["10x10", "20x5", "5x20", "25x40"];
const vertical = ["bottom to top", "top to bottom"];
const horizontal = ["left to right", "right to left"];

export default function GridSettings() {
  const dispatch = useDispatch();

  const handleLayoutChange = (event) => {
    const layout = event.target.value;
    let size = 100;
    switch (layout) {
      case "10x10":
        size = 100;
        break;
      case "20x5":
        size = 100;
        break;
      case "5x20":
        size = 100;
        break;
      case "25x40":
        size = 1000;
        break;
      default:
        size = 100;
    }
    //Checking to see if changing from 1000 back to 100, if so reset count values on outcomes
    // if (arraySize === 1000) {
    //   arrays.forEach((array, arrayIndex) => {
    //     array.outcomes.forEach((outcome, outcomeIndex) => {
    //       const count = 0;
    //       dispatch(updateCount({ arrayIndex, outcomeIndex, count }));
    //     });
    //   });
    // }
    dispatch(setLayout(layout));
    dispatch(setArraySize(size));
  };

  const handleVerticalChange = (event) => {
    dispatch(setVertical(event.target.value));
  };

  const handleHorizontalChange = (event) => {
    dispatch(setHorizontal(event.target.value));
  };

  return (
    <>
      <FormControl>
        <InputLabel id="layout">Layout</InputLabel>
        <Select
          size="small"
          labelId="layout"
          value={useSelector(selectLayout)}
          label="layout"
          onChange={handleLayoutChange}
        >
          {layouts.map((layout) => (
            <MenuItem key={layout} value={layout}>
              {layout}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="h-order">X Order</InputLabel>
        <Select
          size="small"
          labelId="h-order"
          value={useSelector(selectHorizontal)}
          label="h-order"
          onChange={handleHorizontalChange}
        >
          {horizontal.map((hor) => (
            <MenuItem key={hor} value={hor}>
              {hor}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl>
        <InputLabel id="v-order">Y Order</InputLabel>
        <Select
          size="small"
          labelId="v-order"
          value={useSelector(selectVertical)}
          label="v-order"
          onChange={handleVerticalChange}
        >
          {vertical.map((vert) => (
            <MenuItem key={vert} value={vert}>
              {vert}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
}
