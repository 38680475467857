import { createSlice } from "@reduxjs/toolkit";

export const arraySettingsSlice = createSlice({
  name: "arraySettings",
  initialState: {
    arrays: [
      {
        id: 1,
        outcomes: [
          {
            default: false,
            title: "Outcome Value",
            color: "#0030FF",
            count: [24, 100],
            icon: ["Female", "Solid"],
            bdrs: [0, 18],
            legend: "people like you will develop X",
          },
          {
            default: true,
            title: "Default Outcome Value",
            color: "#BBBBBB",
            count: "default",
            icon: ["Female", "Solid"],
            bdrs: [0, 18],
            legend: "people like you will NOT develop X",
          },
        ],
        title: "This is the array title",
        titleColor: "#000000",
        titleSize: "32px",
        titleDisplay: true,
        label: "This is the axis label",
        labelColor: "#000000",
        labelSize: "16px",
        labelDisplay: true,
        legendColor: "#000000",
        legendSize: "14px",
        legendDisplay: true,
        dim: "0x0",
      },
    ],
  },
  reducers: {
    updateColor: (state, { payload }) => {
      const { arrayIndex, outcomeIndex, color } = payload;
      state.arrays[arrayIndex].outcomes[outcomeIndex].color = color;
    },
    updateCount: (state, { payload }) => {
      const { arrayIndex, outcomeIndex, count, arraySize } = payload;
      if (arraySize === 100) {
        state.arrays[arrayIndex].outcomes[outcomeIndex].count[0] =
          parseInt(count);
        return;
      }
      state.arrays[arrayIndex].outcomes[outcomeIndex].count[1] =
        parseInt(count);
    },
    updateIcon: (state, { payload }) => {
      const { arrayIndex, outcomeIndex, icon, arraySize } = payload;
      if (arraySize === 100) {
        state.arrays[arrayIndex].outcomes[outcomeIndex].icon[0] = icon;
        return;
      }
      state.arrays[arrayIndex].outcomes[outcomeIndex].icon[1] = icon;
    },
    updateBdrs: (state, { payload }) => {
      const { arrayIndex, outcomeIndex, bdrs, arraySize } = payload;
      if (arraySize === 100) {
        state.arrays[arrayIndex].outcomes[outcomeIndex].bdrs[0] = bdrs;
        return;
      }
      state.arrays[arrayIndex].outcomes[outcomeIndex].bdrs[1] = bdrs;
    },
    updateLegend: (state, { payload }) => {
      const { arrayIndex, outcomeIndex, legend } = payload;
      state.arrays[arrayIndex].outcomes[outcomeIndex].legend = legend;
    },
    updateTitle: (state, { payload }) => {
      const { arrayIndex, title } = payload;
      state.arrays[arrayIndex].title = title;
    },
    updateTitleColor: (state, { payload }) => {
      const { arrayIndex, color } = payload;
      state.arrays[arrayIndex].titleColor = color;
    },
    updateTitleSize: (state, { payload }) => {
      const { arrayIndex, size } = payload;
      state.arrays[arrayIndex].titleSize = size;
    },
    updateTitleDisplay: (state, { payload }) => {
      const { arrayIndex } = payload;
      state.arrays[arrayIndex].titleDisplay =
        !state.arrays[arrayIndex].titleDisplay;
    },
    updateLabel: (state, { payload }) => {
      const { arrayIndex, title } = payload;
      state.arrays[arrayIndex].label = title;
    },
    updateLabelColor: (state, { payload }) => {
      const { arrayIndex, color } = payload;
      state.arrays[arrayIndex].labelColor = color;
    },
    updateLabelSize: (state, { payload }) => {
      const { arrayIndex, size } = payload;
      state.arrays[arrayIndex].labelSize = size;
    },
    updateLabelDisplay: (state, { payload }) => {
      const { arrayIndex } = payload;
      state.arrays[arrayIndex].labelDisplay =
        !state.arrays[arrayIndex].labelDisplay;
    },
    updateLegendColor: (state, { payload }) => {
      const { arrayIndex, color } = payload;
      state.arrays[arrayIndex].legendColor = color;
    },
    updateLegendSize: (state, { payload }) => {
      const { arrayIndex, size } = payload;
      state.arrays[arrayIndex].legendSize = size;
    },
    updateLegendDisplay: (state, { payload }) => {
      const { arrayIndex } = payload;
      state.arrays[arrayIndex].legendDisplay =
        !state.arrays[arrayIndex].legendDisplay;
    },
    addArray: (state, { payload }) => {
      //const { arrayIndex } = payload;
      const arraysClone = [...state.arrays];
      const arraysLength = arraysClone.length;
      const newArray = {
        id: arraysLength + 1,
        outcomes: [
          {
            default: false,
            title: "Outcome Value",
            color: "#7000FF",
            count: [24, 100],
            icon: ["Female", "Solid"],
            bdrs: [0, 18],
            legend: "people like you will develop X",
          },
          {
            default: true,
            title: "Default Outcome Value",
            color: "#BBBBBB",
            count: "default",
            icon: ["Female", "Solid"],
            bdrs: [0, 18],
            legend: "people like you will NOT develop X",
          },
        ],
        title: "This is the array title",
        titleColor: "#000000",
        titleSize: "32px",
        titleDisplay: true,
        label: "This is the axis label",
        labelColor: "#000000",
        labelSize: "16px",
        labelDisplay: true,
        legendColor: "#000000",
        legendSize: "14px",
        legendDisplay: true,
        dim: "0x0",
      };
      arraysClone.splice(arraysLength, 0, newArray);
      state.arrays = arraysClone;
    },
    removeArray: (state, { payload }) => {
      const { arrayIndex } = payload;
      const arraysClone = [...state.arrays];
      arraysClone.splice(arrayIndex, 1);
      state.arrays = arraysClone;
    },
    addOutCome: (state, { payload }) => {
      const { arrayIndex } = payload;
      const outcomesClone = [...state.arrays[arrayIndex].outcomes];
      const newOutcome = {
        default: false,
        title: "Outcome Value",
        color: "#0030FF",
        count: [0, 0],
        icon: ["Female", "Solid"],
        bdrs: [0, 18],
        legend: "people like you will develop X",
      };
      outcomesClone.splice(outcomesClone.length - 1, 0, newOutcome);
      state.arrays[arrayIndex].outcomes = outcomesClone;
    },
    removeOutCome: (state, { payload }) => {
      const { arrayIndex, outcomeIndex } = payload;
      const outcomesClone = [...state.arrays[arrayIndex].outcomes];
      outcomesClone.splice(outcomeIndex, 1);
      state.arrays[arrayIndex].outcomes = outcomesClone;
    },
    setDim: (state, { payload }) => {
      const { index, dim } = payload;
      state.arrays[index].dim = dim;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateColor,
  updateCount,
  updateIcon,
  updateBdrs,
  updateLegend,
  updateTitle,
  updateTitleColor,
  updateTitleSize,
  updateTitleDisplay,
  updateLabel,
  updateLabelColor,
  updateLabelSize,
  updateLabelDisplay,
  updateLegendColor,
  updateLegendSize,
  updateLegendDisplay,
  addArray,
  removeArray,
  addOutCome,
  removeOutCome,
  setDim,
} = arraySettingsSlice.actions;
export const selectArrays = (state) => state.arraySettings.arrays;

export default arraySettingsSlice.reducer;
