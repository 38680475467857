import styles from "./Icon.module.css";
import { ReactComponent as Male } from "../../images/icons/male.svg";
import { ReactComponent as Female } from "../../images/icons/female.svg";
import { ReactComponent as Neutral } from "../../images/icons/neutral.svg";

function Icon(props) {
  const { icon, color, x, y, bdrs, paddingX, paddingY } = props;
  if (icon === "Solid") {
    return (
      <div
        style={{
          width: `${x}px`,
          height: `${y}px`,
          margin: `${paddingY} ${paddingX}`,
          borderRadius: bdrs,
          backgroundColor: color,
        }}
      ></div>
    );
  }
  return (
    <div
      className={styles.icon}
      style={{
        width: `${x}px`,
        height: `${y}px`,
        margin: `${paddingY} ${paddingX}`,
      }}
    >
      {icon === "Male" && <Male style={{ fill: color }} />}
      {icon === "Female" && <Female style={{ fill: color }} />}
      {icon === "Neutral" && <Neutral style={{ fill: color }} />}
    </div>
  );
}

export default Icon;
