import React, { useState, useEffect } from "react";
//Redux
import { useSelector } from "react-redux";
import { selectArrays } from "../../../../../redux/reducers/arraySettingsSlice";
import {
  selectArraySize,
  selectPaddingX,
  selectTypeface,
  selectIconWidth,
  selectIconHeight,
} from "../../../../../redux/reducers/globalSettingsSlice";
//Styles
import styles from "./Legend.module.css";
import { ReactComponent as Male } from "../../../../../images/icons/male.svg";
import { ReactComponent as Female } from "../../../../../images/icons/female.svg";
import { ReactComponent as Neutral } from "../../../../../images/icons/neutral.svg";

export default function Legend(props) {
  const { index: arrayIndex } = props;
  const arrays = useSelector(selectArrays);
  const outcomes = arrays[arrayIndex].outcomes;
  const total = useSelector(selectArraySize);
  const typeFace = useSelector(selectTypeface);
  const iconXArray = useSelector(selectIconWidth);
  const x = total === 100 ? iconXArray[0] : iconXArray[1];
  const iconYArray = useSelector(selectIconHeight);
  const y = total === 100 ? iconYArray[0] : iconYArray[1];
  const paddingXArray = useSelector(selectPaddingX);
  const paddingX = total === 100 ? paddingXArray[0] : paddingXArray[1];
  const [defaultCount, setDefaultCont] = useState(0);
  const labelDisplay = arrays[arrayIndex].labelDisplay;
  const legendColor = arrays[arrayIndex].legendColor;
  const legendSize = arrays[arrayIndex].legendSize;
  const sizeIndex = total === 100 ? 0 : 1; //needed for ref below

  useEffect(() => {
    let sum = 0;
    outcomes.forEach((outcome) => {
      if (!outcome.default) {
        sum += outcome.count[sizeIndex];
      }
    });
    setDefaultCont(total - sum);
  }, [outcomes, total, sizeIndex]);

  return (
    <div
      className={`${styles.legend} ${styles[typeFace]}`}
      style={{
        width: `${x * 10 + paddingX * 18}px`,
        marginLeft: `${labelDisplay ? 61 : 0}px`,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        {outcomes.map((outcome, index) => (
          <div className={styles.item} key={`legend-${index}`}>
            {outcome.icon[sizeIndex] === "Solid" && (
              <div
                className={styles.icon}
                style={{
                  width: `${x}px`,
                  height: `${y}px`,
                  borderRadius: outcome.bdrs[sizeIndex],
                  backgroundColor: outcome.color,
                }}
              ></div>
            )}
            {outcome.icon[sizeIndex] !== "Solid" && (
              <div
                className={[styles.icon, styles.iconSvg].join(" ")}
                style={{
                  width: `${x}px`,
                  height: `${y}px`,
                }}
              >
                {outcome.icon[sizeIndex] === "Male" && (
                  <Male style={{ fill: outcome.color }} />
                )}
                {outcome.icon[sizeIndex] === "Female" && (
                  <Female style={{ fill: outcome.color }} />
                )}
                {outcome.icon[sizeIndex] === "Neutral" && (
                  <Neutral style={{ fill: outcome.color }} />
                )}
              </div>
            )}
            <div
              style={{
                textAlign: "left",
                color: legendColor,
                fontSize: legendSize,
              }}
            >
              {outcome.default ? defaultCount : outcome.count[sizeIndex]} out of{" "}
              {total} {outcome.legend}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
