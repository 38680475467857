//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectArraySize,
  selectIconWidth,
  setIconWidth,
  selectIconHeight,
  setIconHeight,
} from "../../../../redux/reducers/globalSettingsSlice";
//MUI
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
//Styles
import styles from "./IconSizeSettings.module.css";

export default function IconSizeSettings() {
  const dispatch = useDispatch();
  const arraySize = useSelector(selectArraySize);
  const widthArray = useSelector(selectIconWidth);
  const heightArray = useSelector(selectIconHeight);
  const width = arraySize === 100 ? widthArray[0] : widthArray[1];
  const height = arraySize === 100 ? heightArray[0] : heightArray[1];

  const handleWidthChange = (event, newValue) => {
    dispatch(setIconWidth(event.target.value));
  };

  const handleHeightChange = (event, newValue) => {
    dispatch(setIconHeight(event.target.value));
  };

  return (
    <Stack
      direction={{ xs: "column", lg: "row" }}
      spacing={2}
      alignItems="start"
    >
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="button" display="block" className={styles.label}>
          Icon Width
        </Typography>
        <Slider
          aria-label="Volume"
          value={width}
          onChange={handleWidthChange}
          min={3}
          max={50}
          valueLabelDisplay="on"
          sx={{ width: "120px", color: "#4bb9f4" }}
        />
      </Stack>
      <Stack spacing={2} direction="row" alignItems="center">
        <Typography variant="button" display="block" className={styles.label}>
          Icon Height
        </Typography>
        <Slider
          aria-label="Volume"
          value={height}
          onChange={handleHeightChange}
          min={3}
          max={50}
          valueLabelDisplay="on"
          sx={{ width: "120px", color: "#4bb9f4" }}
        />
      </Stack>
    </Stack>
  );
}
