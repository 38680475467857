import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectArrays,
  updateTitle,
  updateTitleColor,
  updateTitleSize,
  updateTitleDisplay,
} from "../../../../../redux/reducers/arraySettingsSlice";
//MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
//Styles
import styles from "./TitleSettings.module.css";

const fontSizes = ["18px", "24px", "32px"];

export default function TitleSettings(props) {
  const dispatch = useDispatch();
  const { index: arrayIndex } = props;
  const [color, setColor] = useState("");
  const [picker, setPicker] = useState(false);
  const arrays = useSelector(selectArrays);
  const title = arrays[arrayIndex].title;
  const titleColorSetting = arrays[arrayIndex].titleColor;
  const titleSizeSetting = arrays[arrayIndex].titleSize;
  const titleDisplay = arrays[arrayIndex].titleDisplay;

  useEffect(() => {
    setColor(titleColorSetting);
  }, [titleColorSetting]);

  const handlePickerClick = () => {
    setPicker(!picker);
  };

  const handlePickerClose = () => {
    setPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    dispatch(updateTitleColor({ arrayIndex, color: color.hex }));
  };

  const handleSizeChange = (event) => {
    dispatch(updateTitleSize({ arrayIndex, size: event.target.value }));
  };

  const handleTitleChange = (event) => {
    dispatch(updateTitle({ arrayIndex, title: event.target.value }));
  };

  const handleShowHide = (event) => {
    //event.target.checked
    dispatch(updateTitleDisplay({ arrayIndex }));
  };

  return (
    <Box className={styles.border} sx={{ mb: 3 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mb: 2, mt: 1, textAlign: "left" }}
      >
        Title
      </Typography>
      <Box className={styles.controls}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <div style={{ position: "relative", flexShrink: "0" }}>
            <div className={styles.swatch} onClick={handlePickerClick}>
              <div className={styles.color} style={{ background: color }} />
            </div>
            {picker ? (
              <div className={styles.popover}>
                <div className={styles.cover} onClick={handlePickerClose} />
                <SketchPicker
                  color={color}
                  onChangeComplete={handleColorChange}
                />
              </div>
            ) : null}
          </div>
          <FormControl>
            <InputLabel id="titleSize">Title Size</InputLabel>
            <Select
              size="small"
              labelId="titleSize"
              value={titleSizeSetting}
              label="titleSize"
              onChange={handleSizeChange}
              sx={{ minWidth: 100 }}
            >
              {fontSizes.map((fontSize) => (
                <MenuItem key={fontSize} value={fontSize}>
                  {fontSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch checked={titleDisplay} onChange={handleShowHide} />
            }
            label="Show"
          />
        </Stack>
        <Box>
          <TextField
            id="title-text"
            label="Title Text"
            size="small"
            multiline
            fullWidth
            maxRows={4}
            value={title}
            onChange={handleTitleChange}
          />
        </Box>
      </Box>
    </Box>
  );
}
