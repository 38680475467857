import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectLayout,
  selectArraySize,
} from "../../../../../redux/reducers/globalSettingsSlice";
import {
  selectArrays,
  updateColor,
  updateCount,
  updateIcon,
  updateBdrs,
  updateLegend,
  addOutCome,
  removeOutCome,
} from "../../../../../redux/reducers/arraySettingsSlice";
//MUI
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import Typography from "@mui/material/Typography";
import Slider from "@mui/material/Slider";
//Styles
import styles from "./Outcome.module.css";
import { ReactComponent as Male } from "../../../../../images/icons/male.svg";
import { ReactComponent as Female } from "../../../../../images/icons/female.svg";
import { ReactComponent as Neutral } from "../../../../../images/icons/neutral.svg";
import { ReactComponent as Rectangle } from "../../../../../images/icons/rectangle.svg";

const icons = [
  { svg: <Rectangle />, value: "Solid", label: "Solid" },
  { svg: <Male />, value: "Male", label: "Male" },
  { svg: <Female />, value: "Female", label: "Female" },
  { svg: <Neutral />, value: "Neutral", label: "Gender Neutral" },
];

export default function Outcome(props) {
  const dispatch = useDispatch();
  const { arrayIndex, outcomeIndex, isDefault, title, color } = props;
  const [iconColor, setIconColor] = useState("");
  const [iconPicker, setIconPicker] = useState(false);
  const [diff, setDiff] = useState(0);
  const [local, setLocalCount] = useState(0);
  const arrays = useSelector(selectArrays);
  const layout = useSelector(selectLayout);
  const arraySize = useSelector(selectArraySize);
  const countArray = arrays[arrayIndex].outcomes[outcomeIndex].count;
  const count = arraySize === 100 ? countArray[0] : countArray[1];
  const iconArray = arrays[arrayIndex].outcomes[outcomeIndex].icon;
  const icon = arraySize === 100 ? iconArray[0] : iconArray[1];
  const bdrsArray = arrays[arrayIndex].outcomes[outcomeIndex].bdrs;
  const bdrs = arraySize === 100 ? bdrsArray[0] : bdrsArray[1];
  const legend = arrays[arrayIndex].outcomes[outcomeIndex].legend;

  let total = 100;

  switch (layout) {
    case "10x10":
      total = 100;
      break;
    case "20x5":
      total = 100;
      break;
    case "5x20":
      total = 100;
      break;
    case "25x40":
      total = 1000;
      break;
    default:
      total = 100;
  }

  useEffect(() => {
    setIconColor(color);
  }, [color]);

  const handlePickerClick = () => {
    setIconPicker(!iconPicker);
  };

  const handlePickerClose = () => {
    setIconPicker(false);
  };

  const handleColorChange = (color) => {
    setIconColor(color.hex);
    dispatch(updateColor({ arrayIndex, outcomeIndex, color: color.hex }));
  };

  const handleCountChange = (e) => {
    const count = e.target.value;
    let remainder = 0;
    arrays[arrayIndex].outcomes.forEach((outcome, index) => {
      const countArray = outcome.count;
      const count = arraySize === 100 ? countArray[0] : countArray[1];
      if (!outcome.default && outcomeIndex !== index) {
        remainder += count;
      }
    });
    const diff = total - count - remainder;
    setDiff(diff);
    setLocalCount(count);
    if (diff >= 0) {
      dispatch(updateCount({ arrayIndex, outcomeIndex, count, arraySize }));
    }
  };

  const handleIconChange = (e) => {
    const icon = e.target.value;
    dispatch(updateIcon({ arrayIndex, outcomeIndex, icon, arraySize }));
  };

  const handleBdrsChange = (e) => {
    const bdrs = e.target.value;
    dispatch(updateBdrs({ arrayIndex, outcomeIndex, bdrs, arraySize }));
  };

  const handleLegendChange = (e) => {
    const legend = e.target.value;
    dispatch(updateLegend({ arrayIndex, outcomeIndex, legend }));
  };

  const handleAddOutcome = () => {
    dispatch(addOutCome({ arrayIndex }));
  };

  const handleRemove = () => {
    dispatch(removeOutCome({ arrayIndex, outcomeIndex }));
  };

  return (
    <>
      {isDefault && (
        <button className={styles.add} onClick={handleAddOutcome}>
          <span>Add Outcome Value</span>
        </button>
      )}
      <div className={styles.outcome}>
        <div className={styles.title}>
          <div className={styles.titleName}>
            {!isDefault ? (
              <div className={styles.index}>{outcomeIndex + 1}</div>
            ) : null}
            {title}
          </div>
          <RemoveCircleOutlineIcon
            className={styles.remove}
            onClick={handleRemove}
          />
        </div>
        <Box className={styles.controls}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            alignItems="center"
          >
            <div style={{ position: "relative", flexShrink: "0" }}>
              <div className={styles.swatch} onClick={handlePickerClick}>
                <div className={styles.color} style={{ background: color }} />
              </div>
              {iconPicker ? (
                <div className={styles.popover}>
                  <div className={styles.cover} onClick={handlePickerClose} />
                  <SketchPicker
                    color={iconColor}
                    onChangeComplete={handleColorChange}
                  />
                </div>
              ) : null}
            </div>
            {!isDefault && (
              <div style={{ flexShrink: "0", maxWidth: "80px" }}>
                <TextField
                  size="small"
                  label="Count"
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 1, max: 100 }}
                  value={diff < 0 ? local : count}
                  error={diff < 0}
                  helperText={diff < 0 ? `Value is to large` : ""}
                  onChange={handleCountChange}
                />
              </div>
            )}
            <div style={{ flexShrink: "0", flexGrow: "1", minWidth: "171px" }}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="icon">Icon</InputLabel>
                <Select
                  size="small"
                  labelId="icon"
                  value={icon}
                  label="icon"
                  onChange={handleIconChange}
                >
                  {icons.map((icon, index) => (
                    <MenuItem key={`${icon.value}-${index}`} value={icon.value}>
                      <div className={styles.iconList}>
                        <div className={styles.iconSvg}>{icon.svg}</div>
                        <div className={styles.iconLabel}>{icon.label}</div>
                      </div>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </Stack>
          {icon === "Solid" && (
            <Stack
              spacing={2}
              direction="row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              alignItems="center"
            >
              <Typography
                variant="button"
                display="block"
                className={styles.label}
              >
                {`Icon Radius ${bdrs}`}
              </Typography>
              <Slider
                aria-label="Volume"
                value={bdrs}
                onChange={handleBdrsChange}
                min={0}
                max={18}
                valueLabelDisplay="off"
                sx={{
                  width: "120px",
                  color: "#4bb9f4",
                  flexGrow: 1,
                }}
              />
            </Stack>
          )}
          <Box>
            <TextField
              id="outlined-multiline-flexible"
              label="Legend Text"
              size="small"
              multiline
              fullWidth
              maxRows={4}
              value={legend}
              onChange={handleLegendChange}
            />
          </Box>
        </Box>
      </div>
    </>
  );
}
