//Redux
import { useSelector } from "react-redux";
import { selectArrays } from "../../../../redux/reducers/arraySettingsSlice";
//Styles
import styles from "./ArrayOutcomes.module.css";
//Components
import Outcome from "./Outcome/Outcome";

export default function ArrayOutcomes(props) {
  const { index: arrayIndex } = props;
  const outcomes = useSelector(selectArrays)[arrayIndex].outcomes;
  return (
    <div className={styles.outcomes}>
      {outcomes.map((outcome, index) => (
        <Outcome
          key={`outcome-${index}`}
          arrayIndex={arrayIndex}
          outcomeIndex={index}
          isDefault={outcome.default}
          title={outcome.title}
          color={outcome.color}
        />
      ))}
    </div>
  );
}
