//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectRandom,
  setRandom,
} from "../../../redux/reducers/globalSettingsSlice";
//MUI
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
//Styles
import styles from "./GlobalSettings.module.css";
//Components
import GridSettings from "./GridSettings/GridSettings";
import FontSettings from "./FontSettings/FontSettings";
import IconSettings from "./IconSettings/IconSettings";
import ColorSettings from "./ColorSettings/ColorSettings";
import IconSizeSettings from "./IconSizeSettings/IconSizeSettings";

export default function GlobalSettings() {
  const dispatch = useDispatch();
  const random = useSelector(selectRandom);

  const handleRandom = (event) => {
    //event.target.checked
    dispatch(setRandom({}));
  };
  return (
    <Stack className={styles.controls} spacing={2}>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="start"
      >
        <GridSettings />
        <FontSettings />
        <FormControlLabel
          control={<Switch checked={random} onChange={handleRandom} />}
          label="Randomize"
        />
      </Stack>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        spacing={2}
        alignItems="start"
        style={{ marginTop: "45px" }}
      >
        <ColorSettings />
        <IconSettings />
        <IconSizeSettings />
      </Stack>
    </Stack>
  );
}
