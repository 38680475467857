import React, { useState } from "react";
//Styles
import styles from "./ArraySettings.module.css";
//MUI
import SettingsIcon from "@mui/icons-material/Settings";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
//Components
import TitleSettings from "./TitleSettings/TitleSettings";
import LabelSettings from "./LabelSettings/LabelSettings";
import LegendSettings from "./LegendSettings/LegendSettings";

export default function ArraySettings(props) {
  const { index } = props;
  const [open, setOpen] = useState(false);
  const dynamicClass = open ? styles.open : styles.closed;

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={`${styles.settings} ${dynamicClass}`}>
      <div className={styles.title} onClick={handleClick}>
        Titles &amp; Legends{" "}
        {!open ? (
          <SettingsIcon sx={{ ml: 1 }} />
        ) : (
          <HighlightOffIcon sx={{ ml: 1 }} />
        )}
      </div>
      <div className={styles.controls}>
        <TitleSettings index={index} />
        <LabelSettings index={index} />
        <LegendSettings index={index} />
      </div>
    </div>
  );
}
