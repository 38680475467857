import React, { useState } from "react";
import { Link } from "react-router-dom";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectDisplay,
  toggleDisplay,
} from "../../redux/reducers/globalSettingsSlice";
import {
  selectArrays,
  addArray,
  removeArray,
} from "../../redux/reducers/arraySettingsSlice";
//MUI
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DownloadIcon from "@mui/icons-material/Download";
//Styles
import styles from "./Builder.module.css";
//Components
import ArrayAssembly from "./ArrayAssembly/ArrayAssembly";
import GlobalSettings from "./GlobalSettings/GlobalSettings";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Builder() {
  const dispatch = useDispatch();
  const arrays = useSelector(selectArrays);
  const showGlobalSettings = useSelector(selectDisplay);
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleNewArray = () => {
    dispatch(addArray({}));
  };

  const handleRemoveArray = () => {
    dispatch(removeArray({ arrayIndex: value }));
    setValue(0);
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleGlobalSettings = () => {
    dispatch(toggleDisplay({}));
  };

  return (
    <main className={styles.main}>
      <Box className={styles.column}>
        <Box className={styles.header}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
          >
            <Button variant="contained" onClick={handleGlobalSettings}>
              Global Settings{" "}
              {!showGlobalSettings ? (
                <SettingsIcon sx={{ ml: 1 }} />
              ) : (
                <HighlightOffIcon sx={{ ml: 1 }} />
              )}
            </Button>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={2}
              alignItems="center"
            >
              <Box
                sx={{
                  maxWidth: { xs: 150, sm: 480 },
                  bgcolor: "background.paper",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons={false}
                  aria-label="scrollable auto tabs"
                >
                  {arrays.map((array, index) => (
                    <Tab
                      key={index}
                      label={`Array ${index + 1}`}
                      sx={{ minHeight: "unset" }}
                      icon={
                        arrays.length > 1 ? (
                          <RemoveCircleOutlineIcon
                            sx={{ width: "20px", height: "auto" }}
                            onClick={handleOpen}
                          />
                        ) : null
                      }
                      iconPosition="end"
                      {...a11yProps(index)}
                    />
                  ))}
                </Tabs>
              </Box>
              <Button
                className={styles.new}
                style={{
                  color: "#000",
                  margin: "-10px 0 0 10px",
                }}
                onClick={handleNewArray}
                endIcon={<AddCircleOutlineIcon />}
              >
                Add new array
              </Button>
            </Stack>
            <Link to="/download">
              <Button
                variant="contained"
                sx={{ height: "100%", width: { xs: "100%", sm: "auto" } }}
              >
                Preview/Download <DownloadIcon sx={{ ml: 1 }} />
              </Button>
            </Link>
          </Stack>
          {showGlobalSettings && <GlobalSettings />}
        </Box>
        {arrays.map((array, index) => (
          <TabPanel key={`arrayassembly-${index}`} value={value} index={index}>
            <ArrayAssembly index={index} />
          </TabPanel>
        ))}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to remove this array?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Once the array is removed there is no getting it back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRemoveArray} autoFocus>
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    </main>
  );
}
