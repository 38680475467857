import React, { useState, useEffect } from "react";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectLayout,
  selectArraySize,
  selectIconWidth,
  selectIconHeight,
  selectPaddingX,
  selectPaddingY,
  selectVertical,
  selectHorizontal,
  selectTypeface,
  selectRandom,
} from "../../../../../redux/reducers/globalSettingsSlice";
import {
  selectArrays,
  setDim,
} from "../../../../../redux/reducers/arraySettingsSlice";
//Styles
import styles from "./Array.module.css";
//Componenets
import Icon from "../../../../Icon/Icon";

export default function Array(props) {
  const dispatch = useDispatch();
  const { index } = props;
  const arrays = useSelector(selectArrays);
  const layout = useSelector(selectLayout);
  const arraySize = useSelector(selectArraySize);
  const iconXArray = useSelector(selectIconWidth);
  const iconX = arraySize === 100 ? iconXArray[0] : iconXArray[1];
  const iconYArray = useSelector(selectIconHeight);
  const iconY = arraySize === 100 ? iconYArray[0] : iconYArray[1];
  const paddingXArray = useSelector(selectPaddingX);
  const paddingX = arraySize === 100 ? paddingXArray[0] : paddingXArray[1];
  const paddingYArray = useSelector(selectPaddingY);
  const paddingY = arraySize === 100 ? paddingYArray[0] : paddingYArray[1];
  const verticalLayout = useSelector(selectVertical);
  const horizontalLayout = useSelector(selectHorizontal);
  const typeFace = useSelector(selectTypeface);
  const random = useSelector(selectRandom);
  const array = arrays[index];
  const outcomes = array.outcomes;
  const label = array.label;
  const labelColor = arrays[index].labelColor;
  const labelSize = arrays[index].labelSize;
  const labelDisplay = arrays[index].labelDisplay;
  const [direction, setDirection] = useState("ltr");

  let columns = 10;
  let total = 100;
  let rows = 10;
  let numPerRow = 10;

  useEffect(() => {
    const divWidth = document.getElementById(`array-size-${index}`).offsetWidth;
    const divHeight = document.getElementById(
      `array-size-${index}`
    ).offsetHeight;
    const dim = `${divWidth}x${divHeight}`;
    dispatch(setDim({ index, dim }));
  });

  useEffect(() => {
    let directionValue = "ltr";
    switch (horizontalLayout) {
      case "left to right":
        if (verticalLayout === "bottom to top") {
          directionValue = "rtl";
        } else {
          directionValue = "ltr";
        }
        break;
      case "right to left":
        if (verticalLayout === "bottom to top") {
          directionValue = "ltr";
        } else {
          directionValue = "rtl";
        }
        break;
      default:
        directionValue = "ltr";
    }
    setDirection(directionValue);
  }, [index, dispatch, horizontalLayout, verticalLayout]);

  switch (layout) {
    case "10x10":
      columns = 10;
      total = 100;
      rows = 10;
      numPerRow = 10;
      break;
    case "20x5":
      columns = 20;
      total = 100;
      rows = 5;
      numPerRow = 20;
      break;
    case "5x20":
      columns = 5;
      total = 100;
      rows = 20;
      numPerRow = 5;
      break;
    case "25x40":
      columns = 25;
      total = 1000;
      rows = 40;
      numPerRow = 25;
      break;
    default:
      columns = 10;
      total = 100;
      rows = 10;
      numPerRow = 10;
  }

  let icons = [];
  let marks = [];

  outcomes.forEach((outcome, index) => {
    const isDefault = outcome.default;
    const countArray = !outcome.count ? [0, 0] : outcome.count;
    const count = arraySize === 100 ? countArray[0] : countArray[1];
    const color = outcome.color;
    const iconArray = outcome.icon;
    const icon = arraySize === 100 ? iconArray[0] : iconArray[1];
    const bdrsArray = outcome.bdrs;
    const bdrs = arraySize === 100 ? bdrsArray[0] : bdrsArray[1];
    let items = count;
    if (!isDefault) {
      total = total - count;
    } else {
      items = total;
    }
    for (var i = 0; i < items; i++) {
      icons.push(
        <Icon
          key={`${index}-${i}`}
          icon={icon}
          color={color}
          x={iconX}
          y={iconY}
          bdrs={`${bdrs}px`}
          paddingX={`${paddingX}px`}
          paddingY={`${paddingY}px`}
        />
      );
    }
  });

  for (var m = 0; m < rows + 1; m++) {
    marks.push(
      <div
        key={`mark-${m}`}
        className={styles.mark}
        style={{ height: `${iconY}px`, margin: `${paddingY}px 0` }}
      >
        <div className={styles.markerNumber} style={{ color: labelColor }}>
          {m * numPerRow} --
        </div>
      </div>
    );
  }

  const getShuffledArr = (arr) => {
    const newArr = arr.slice();
    for (let i = newArr.length - 1; i > 0; i--) {
      const rand = Math.floor(Math.random() * (i + 1));
      [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
    }
    return newArr;
  };
  let iconMarkUp;
  if (!random) {
    iconMarkUp = verticalLayout === "bottom to top" ? icons.reverse() : icons;
  } else {
    iconMarkUp = getShuffledArr(icons);
  }

  return (
    <div className={styles.group}>
      {labelDisplay && (
        <div
          id={`axis-label-${index}`}
          className={`${styles.label} ${styles[typeFace]}`}
        >
          <div
            className={styles.labelText}
            style={{
              color: labelColor,
              fontSize: labelSize,
              width: labelSize,
            }}
          >
            <div className={styles.rotate}>{label}</div>
          </div>
          <div
            className={`${styles.marks} ${styles[`mark-size-${arraySize}`]}`}
            style={{ top: `-${(iconY + paddingY * 2) / 2}px` }}
          >
            {marks.reverse()}
          </div>
        </div>
      )}
      <div
        className={styles.array}
        style={{
          gridTemplateColumns: `repeat(${columns}, 1fr)`,
          direction: `${direction}`,
        }}
      >
        {iconMarkUp}
      </div>
    </div>
  );
}
