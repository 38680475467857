import * as htmlToImage from "html-to-image";
import download from "downloadjs";

async function convertToJpegAndDownload(el, filename) {
  try {
    // The following 3 awaits are a work around for Safari
    // Without them Safari will not include the text in the
    // generated image the first time you try to download
    await htmlToImage.toJpeg(el);
    await htmlToImage.toJpeg(el);
    await htmlToImage.toJpeg(el);
    const dataUrl = await htmlToImage.toJpeg(el);
    download(dataUrl, filename);
  } catch (error) {
    console.error("Failed to convert to JPEG and download", error);
  }
}

async function convertToPngAndDownload(el, filename) {
  try {
    // The following 3 awaits are a work around for Safari
    // Without them Safari will not include the text in the
    // generated image the first time you try to download
    await htmlToImage.toPng(el);
    await htmlToImage.toPng(el);
    await htmlToImage.toPng(el);
    const dataUrl = await htmlToImage.toPng(el);
    download(dataUrl, filename);
  } catch (error) {
    console.error("Failed to convert to JPEG and download", error);
  }
}

const exportImage = (el, file, filename) => {
  switch (file) {
    case "jpg":
      document.fonts.ready.then(function () {
        convertToJpegAndDownload(el, filename);
      });
      break;
    case "png":
      document.fonts.ready.then(function () {
        convertToPngAndDownload(el, filename);
      });
      break;
    case "svg":
      htmlToImage.toSvg(el).then(function (dataUrl) {
        download(dataUrl, filename);
      });
      break;
    default:
      htmlToImage.toPng(el).then(function (dataUrl) {
        download(dataUrl, filename);
      });
  }
};
export default exportImage;
