import { configureStore } from "@reduxjs/toolkit";
import globalSettingsReducer from "./reducers/globalSettingsSlice";
import arraySettingsReducer from "./reducers/arraySettingsSlice";

export default configureStore({
  reducer: {
    globalSettings: globalSettingsReducer,
    arraySettings: arraySettingsReducer,
  },
});
