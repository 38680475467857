import Box from "@mui/material/Box";
// import Paper from "@mui/material/Paper";
// import Typography from "@mui/material/Typography";

import brian from "../../images/bzf.jpeg";
import holly from "../../images/how_200-382d4c4ed894e314c07d56785cf37df6.jpeg";
import example from "../../images/icon-array-example.gif";
import styles from "./Details.module.css";

export default function Details() {
  return (
    <Box>
      <Box sx={{ maxWidth: "728px", margin: "0 auto" }}>
        <h1>About</h1>
        <Box sx={{ mb: 8 }}>
          <div className={styles["head-shot"]}>
            <img
              alt="Brian J. Zikmund-Fisher, PhD"
              className={styles["pull-right"]}
              src={brian}
            />
          </div>

          <p>
            <em>Iconarray.com</em> is a project of{" "}
            <strong>
              <a
                href="https://sph.umich.edu/faculty-profiles/zikmundfisher-brian.html"
                target="_blank"
                rel="noreferrer"
              >
                Brian J. Zikmund-Fisher, PhD
              </a>
            </strong>
            , a decision psychologist and public health communications
            researcher who holds appointments as Professor of Health Behavior
            and Health Education, Research Associate Professor of Internal
            Medicine, and Associate Director of the{" "}
            <a
              href="https://cbssm.med.umich.edu/"
              target="_blank"
              rel="noreferrer"
            >
              Center for Bioethics and Social Sciences in Medicine (CBSSM)
            </a>{" "}
            at the University of Michigan. He is also the Editor-in-Chief
            (2021-2025) of the journals{" "}
            <a
              href="http://journals.sagepub.com/home/mdm"
              target="_blank"
              rel="noreferrer"
            >
              <em>Medical Decision Making</em>
            </a>{" "}
            and{" "}
            <a
              href="http://journals.sagepub.com/home/mpp"
              target="_blank"
              rel="noreferrer"
            >
              <em>MDM Policy &amp; Practice</em>
            </a>
            .
          </p>

          <p>
            Dr. Zikmund-Fisher uses his interdisciplinary background in decision
            psychology and behavioral economics to design and evaluate methods
            of making health data more intuitively meaningful, to study the
            impact of people's consistent preferences for more versus less
            health care on over- and underutilization of care, and to explore
            the power of narratives in health communications.
          </p>
        </Box>
        <Box sx={{ mb: 8 }}>
          <div className={styles["head-shot"]}>
            <img
              alt="Holly&nbsp;O. Witteman, PhD"
              className={styles["pull-right"]}
              src={holly}
            />
          </div>

          <p>
            Design of earlier iterations of <em>Iconarray.com</em> were also
            guided by{" "}
            <a
              href="https://www.fmed.ulaval.ca/faculte-et-reseau/a-propos-de-la-faculte/direction-et-gouvernance/holly-witteman/"
              target="_blank"
              rel="noreferrer"
            >
              Holly&nbsp;O. Witteman, PhD
            </a>
            . Dr. Witteman is a human factors engineer who completed a
            postdoctoral fellowship at CBSSM at the University of Michigan,
            2009-2011 and worked closely with Dr. Zikmund-Fisher on the design
            and development of Iconarray.com. She is currently an Associate
            Professor in the Department of Family and Emergency Medicine in the
            Faculty of Health Sciences at Université Laval in Quebec City,
            Canada.
          </p>

          <p>
            Dr. Witteman brings interdisciplinary training in mathematics, human
            factors engineering and social sciences to the study of technologies
            for risk communication and values clarification. She advocates
            reality-based design, which means designing for the way people are,
            not the way we wish they were. Her research addresses how people use
            online applications when making health decisions, how technologies
            can make risk numbers more meaningful for people, how tools might be
            designed and used to help clinicians and patients better understand
            and talk about risks and values, and how to better design these
            tools in ways that improve the user experience.
          </p>
        </Box>
        <Box sx={{ mb: 8 }}>
          <p>
            The following people provided substantial input into the design of
            various iterations of iconarray.com or the earlier CBSSM pictograph
            generator:
          </p>

          <ul>
            <li>Christopher Marr, BS</li>
            <li>Jeffrey Rosczyk, BS, BFA</li>
            <li>Ian Moore, BFA</li>
            <li>Mark Dickson, MA</li>
            <li>Nicole L. Exe, MPH</li>
            <li>Valerie Kahn, MPH</li>
            <li>James Rampton, MPH/MSI</li>
            <li>Bob Burbach, BA</li>
            <li>Jon Kulpa, BA/BS</li>
          </ul>
        </Box>
        <Box sx={{ mb: 8 }}>
          <img
            alt="icon array"
            width="250px"
            className={styles.example}
            src={example}
          />
          <h2>Why Use Icon Arrays</h2>

          <p>
            Icon arrays are a type of risk display that uses a matrix of icons
            (usually 100 or 1000 icons) to represent an at-risk population,
            simultaneously displaying both the number of expected events and the
            number of expected non-events. As a result, icon arrays have several
            advantages over simple numerical displays and other types of visual
            displays.
          </p>

          <ol>
            <li>
              Icon arrays can be read simply by counting icons. This enables
              icon arrays to be more precisely read than bar or pie charts.
              Research suggests that counting icons is particularly common among
              more numerate readers.
            </li>
            <li>
              Icon arrays show the part-whole relationship clearly in both
              relative count and relative area, thus embodying one of the
              advantages of pie charts and providing a significant advantage
              over bar charts and numerical representations.
            </li>
            <li>
              Icon arrays are inherently a frequency-based representation of
              risk. Research has shown that many people, especially the less
              numerate, respond differently to frequency representations of risk
              than they do to percentages.
            </li>
            <li>
              The icon arrays generated by Iconarray.com are set by default to
              build the icons representing risk events from the bottom upwards
              by rows. As a result, these icon arrays have a rough height cue as
              well (displays of larger risks have colored icons rising higher
              than displays of lower risks), thus mirroring bar graphs in format
              as well. However, advanced users can use the Global Settings to
              adjust the fill patterns.
            </li>
          </ol>

          <p>
            While we prefer the term "icon array" to describe this type of
            graphic, we are aware that similar displays have been referred to by
            numerous other titles, such as{" "}
            <em>
              icon array, pictograph, pictogram, people chart, cates plot,
            </em>{" "}
            and <em>personograph</em>.
          </p>
          <p>We are not alone in recommending icon arrays. </p>
        </Box>
        <Box sx={{ mb: 8 }}>
          <h2>Sponsorship</h2>
          <p>
            The development and hosting of <em>Iconarray.com</em> has been
            sponsored by the the{" "}
            <a
              href="https://cbssm.med.umich.edu/"
              target="_blank"
              rel="noreferrer"
            >
              UM Center for Bioethics and Social Sciences in Medicine (CBSSM)
            </a>
            .
          </p>

          <p>
            The current version of <em>Iconarray.com</em> was created by
            Christopher Marr and Jeffrey Rosczyk of the{" "}
            <a href="https://chcr.umich.edu" target="_blank" rel="noreferrer">
              UM Center for Health Communication Research (CHCR)
            </a>
            .
          </p>
        </Box>
        <Box sx={{ mb: 8 }}>
          <h3>Disclaimer</h3>
          <p>
            The <em>Iconarray.com</em> site is a demonstration project and is
            provided “as-is” with no guarantees of service. While we have
            attempted to test a variety of parameters, it is likely that certain
            combinations will result in non-optimal graphics and/or failures.
          </p>
        </Box>
      </Box>
    </Box>
  );
}
