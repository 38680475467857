import React, { useState } from "react";
import { SketchPicker } from "react-color";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectBgcolor,
  setBgcolor,
} from "../../../../redux/reducers/globalSettingsSlice";
//MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
//Styles
import styles from "./ColorSettings.module.css";

export default function GridSettings() {
  const dispatch = useDispatch();
  const [bgColor, setBgColor] = useState("#fff");
  const [bgPicker, setBgPicker] = useState(false);

  const handleBackgroundColorChange = (color) => {
    setBgColor(color.hex);
    dispatch(setBgcolor(color.hex));
  };

  const handleBackgroundPickerClick = () => {
    setBgPicker(!bgPicker);
  };

  const handleBackgroundPickerClose = () => {
    setBgPicker(false);
  };

  return (
    <Box className={styles.controls}>
      <Typography variant="button" display="block" sx={{ mr: 1 }}>
        Background
      </Typography>
      <Box sx={{ position: "relative" }}>
        <div className={styles.swatch} onClick={handleBackgroundPickerClick}>
          <div
            className={styles.color}
            style={{ background: useSelector(selectBgcolor) }}
          />
        </div>
        {bgPicker ? (
          <div className={styles.popover}>
            <div
              className={styles.cover}
              onClick={handleBackgroundPickerClose}
            />
            <SketchPicker
              color={bgColor}
              onChangeComplete={handleBackgroundColorChange}
            />
          </div>
        ) : null}
      </Box>
    </Box>
  );
}
