import React, { useState, useEffect } from "react";
import { SketchPicker } from "react-color";
//Redux
import { useSelector, useDispatch } from "react-redux";
import {
  selectArrays,
  updateLabel,
  updateLabelColor,
  updateLabelSize,
  updateLabelDisplay,
} from "../../../../../redux/reducers/arraySettingsSlice";
//MUI
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
//Styles
import styles from "./LabelSettings.module.css";

const fontSizes = ["16px", "18px", "24px"];

export default function LabelSettings(props) {
  const dispatch = useDispatch();
  const { index: arrayIndex } = props;
  const [color, setColor] = useState("");
  const [picker, setPicker] = useState(false);
  const arrays = useSelector(selectArrays);
  const label = arrays[arrayIndex].label;
  const labelColorSetting = arrays[arrayIndex].labelColor;
  const labelSizeSetting = arrays[arrayIndex].labelSize;
  const labelDisplay = arrays[arrayIndex].labelDisplay;

  useEffect(() => {
    setColor(labelColorSetting);
  }, [labelColorSetting]);

  const handlePickerClick = () => {
    setPicker(!picker);
  };

  const handlePickerClose = () => {
    setPicker(false);
  };

  const handleColorChange = (color) => {
    setColor(color.hex);
    dispatch(updateLabelColor({ arrayIndex, color: color.hex }));
  };

  const handleSizeChange = (event) => {
    dispatch(updateLabelSize({ arrayIndex, size: event.target.value }));
  };

  const handleLabelChange = (event) => {
    dispatch(updateLabel({ arrayIndex, title: event.target.value }));
  };

  const handleShowHide = (event) => {
    //event.target.checked
    dispatch(updateLabelDisplay({ arrayIndex }));
  };

  return (
    <Box className={styles.border} sx={{ mb: 3 }}>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ mb: 2, mt: 1, textAlign: "left" }}
      >
        Axis Label
      </Typography>
      <Box className={styles.controls}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <div style={{ position: "relative", flexShrink: "0" }}>
            <div className={styles.swatch} onClick={handlePickerClick}>
              <div className={styles.color} style={{ background: color }} />
            </div>
            {picker ? (
              <div className={styles.popover}>
                <div className={styles.cover} onClick={handlePickerClose} />
                <SketchPicker
                  color={color}
                  onChangeComplete={handleColorChange}
                />
              </div>
            ) : null}
          </div>
          <FormControl>
            <InputLabel id="labelSize">Size</InputLabel>
            <Select
              size="small"
              labelId="labelSize"
              value={labelSizeSetting}
              label="labelSize"
              onChange={handleSizeChange}
              sx={{ minWidth: 100 }}
            >
              {fontSizes.map((fontSize) => (
                <MenuItem key={fontSize} value={fontSize}>
                  {fontSize}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Switch checked={labelDisplay} onChange={handleShowHide} />
            }
            label="Show"
          />
        </Stack>
        <Box>
          <TextField
            id="label-text"
            label="Label Text"
            size="small"
            multiline
            fullWidth
            maxRows={4}
            value={label}
            onChange={handleLabelChange}
          />
        </Box>
      </Box>
    </Box>
  );
}
